import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';

import { environment } from '../../environments/environment';
import { QueryResult } from '../models/query-result.model';
import { Tag } from '../models/tag.model';
import { Contents } from '../models/contents.model';

@Injectable({
  providedIn: 'root',
})
export class TagService {
  constructor(private http: HttpClient) {}

  getAll(
    offset: number,
    limit: number,
    q: string
  ): Observable<QueryResult<Tag>> {
    const params: any = { offset, limit, q };

    return this.http.get<QueryResult<Tag>>(`${environment.apiEndpoint}/tags`, {
      params,
      withCredentials: true,
    });
  }

  getTags(
    categoryId: string,
    offset: number,
    limit: number,
    q?: string
  ): Observable<QueryResult<Tag>> {
    const params: any = { offset, limit };

    if (q) {
      params.q = q;
    }

    return this.http.get<QueryResult<Tag>>(
      `${environment.apiEndpoint}/categories/${categoryId}/tags`,
      { params, withCredentials: true }
    );
  }

  getTag(tagId: string): Observable<Tag> {
    return this.http.get<Tag>(`${environment.apiEndpoint}/tags/${tagId}`, {
      withCredentials: true,
    });
  }

  getTagContents(
    tagId: string,
    offset: number,
    limit: number
  ): Observable<{ total: number; items: Contents[] }> {
    const params: any = { offset, limit };

    return this.http.get<any>(
      `${environment.apiEndpoint}/tags/${tagId}/contents`,
      {
        params,
        withCredentials: true,
      }
    );
  }

  create(categoryId: string, tag: Tag): Observable<Tag> {
    return this.http.post<Tag>(
      `${environment.apiEndpoint}/categories/${categoryId}/tags`,
      tag,
      { withCredentials: true }
    );
  }

  update(tag: Tag): Observable<Tag> {
    return this.http.put<Tag>(
      `${environment.apiEndpoint}/tags/${tag.id}`,
      tag,
      { withCredentials: true }
    );
  }

  delete(tagId): Observable<void> {
    return this.http.delete<void>(`${environment.apiEndpoint}/tags/${tagId}`, {
      withCredentials: true,
    });
  }
}
