import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Subject } from 'rxjs';
import { map, takeUntil } from 'rxjs/operators';

import { UserService } from '../../services/user.service';
import { ContentsService } from '../../services/contents.service';

import { Contents, ContentsType } from '../../models/contents.model';
@Component({
  selector: 'app-contents-home-page',
  templateUrl: './contents-home-page.component.html',
  styleUrls: ['./contents-home-page.component.scss'],
})
export class ContentsHomePageComponent implements OnInit {
  private unsubscriber: Subject<void> = new Subject<void>();

  public itemCountPerPage = 10;
  public totalItemCount: number;
  public tabs = [
    'book',
    'post',
    'channel',
    'writer',
    'imprint',
    'library',
    'magazine',
  ];
  public selectedTab: ContentsType;
  public page: number;
  public items: Contents[];

  public loading: boolean;
  public isAdmin: boolean;

  constructor(
    private activatedRoute: ActivatedRoute,
    private router: Router,
    private userService: UserService,
    private contentsService: ContentsService
  ) {}

  ngOnInit(): void {
    this.activatedRoute.queryParams
      .pipe(
        map((queryParams) => ({
            tab: queryParams.tab ? queryParams.tab : '',
            page: queryParams.page
              ? parseInt(queryParams.page, undefined)
              : undefined,
          }))
      )
      .subscribe(({ tab, page }) => {
        const checkedTab = tab || 'book';
        const checkedPage = page || 1;

        if (tab !== checkedTab && page !== checkedPage) {
          const newQueryParams: any = {
            tab: checkedTab,
            page: checkedPage,
          };
          this.router.navigate([], {
            queryParams: newQueryParams,
            replaceUrl: true,
          });
          return;
        }

        if (this.selectedTab !== tab || this.page !== page) {
          this.loadItems(tab, page);
        }

        this.selectedTab = tab;
        this.page = page;
      });

    this.userService.user$
      .pipe(takeUntil(this.unsubscriber))
      .subscribe((user) => (this.isAdmin = user.isAdmin));
  }

  updateQueryParams(queryParams: any): void {
    this.router.navigate([], {
      queryParams,
      queryParamsHandling: 'merge',
    });
  }

  loadItems(tab: ContentsType, page: number): void {
    const offset = (page - 1) * this.itemCountPerPage;
    const limit = this.itemCountPerPage;

    this.loading = true;

    this.contentsService
      .getAllContents(tab, offset, limit)
      .subscribe((result) => {
        this.totalItemCount = result.total;
        this.items = result.items;

        this.loading = false;
      });
  }

  pageChanged(page: number /* zero based */): void {
    this.updateQueryParams({ page: page + 1 });
  }
}
