import { Component, OnInit, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

export interface ConfirmDialogConfig {
  title: string;
  message: string;
  okButtonText: string;
  cancelButtonText: string;
  cancelable: boolean;
  isNegativeAction: boolean;
}

@Component({
  selector: 'app-confirm-dialog',
  templateUrl: './confirm-dialog.component.html',
  styleUrls: ['./confirm-dialog.component.scss'],
})
export class ConfirmDialogComponent implements OnInit {
  constructor(
    public dialogRef: MatDialogRef<ConfirmDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: ConfirmDialogConfig
  ) {}

  ngOnInit(): void {
    this.dialogRef.updateSize('340px');
    this.dialogRef.disableClose = true;
  }
}
