import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { map, filter, takeUntil, switchMap } from 'rxjs/operators';
import { Subject, zip } from 'rxjs';
import { MatDialog } from '@angular/material/dialog';
import { HttpErrorResponse } from '@angular/common/http';

import { SnackBarService } from '../../services/snack.bar.service';
import { TagService } from '../../services/tag.service';
import { RelationshipService } from '../../services/relationship.service';
import { UserService } from '../../services/user.service';
import { Tag } from '../../models/tag.model';

import { TagEditDialogComponent } from '../../dialogs/tag-edit-dialog/tag-edit-dialog.component';
import { TaggingDialogComponent } from '../../dialogs//tagging-dialog/tagging-dialog.component';
import { ConfirmDialogComponent } from 'src/app/dialogs/confirm-dialog/confirm-dialog.component';

@Component({
  selector: 'app-tag-page',
  templateUrl: './tag-page.component.html',
  styleUrls: ['./tag-page.component.scss'],
})
export class TagPageComponent implements OnInit {
  private unsubscriber: Subject<void> = new Subject<void>();

  public tagContentsCountPerPage = 10;
  public totalTagContentsCount: number;
  public page: number;

  private tagId: string;
  public tag: Tag;
  public tagContents;
  public watchTag: boolean;
  public isAdmin: boolean;
  public loading: boolean;
  public isCopied = false;

  constructor(
    private activatedRoute: ActivatedRoute,
    private router: Router,
    private matDialog: MatDialog,
    private tagService: TagService,
    private snackBarService: SnackBarService,
    private relationshipService: RelationshipService,
    private userService: UserService
  ) {}

  ngOnInit(): void {
    this.activatedRoute.params.subscribe((parameter) => {
      if (this.tagId !== parameter.tagId) {
        this.tagId = parameter.tagId;
        this.loadTagPage(1);
      }
    });

    this.activatedRoute.queryParams
      .pipe(
        map((queryParams) => ({
            page: queryParams.page
              ? parseInt(queryParams.page, undefined)
              : undefined,
          }))
      )
      .subscribe(({ page }) => {
        const checkedPage = page || 1;

        if (page !== checkedPage) {
          const newQueryParams: any = {
            page: checkedPage,
          };
          this.router.navigate([], {
            queryParams: newQueryParams,
            replaceUrl: true,
          });
          return;
        }

        if (this.page !== page) {
          this.loadTagPage(page);
        }

        this.page = page;
      });

    this.relationshipService.query(this.tagId, 'likes').subscribe((result) => {
      this.watchTag = result.likes;
    });

    this.userService.user$
      .pipe(takeUntil(this.unsubscriber))
      .subscribe((user) => (this.isAdmin = user.isAdmin));
  }

  loadTagPage(page: number): void {
    const offset = (page - 1) * this.tagContentsCountPerPage;
    const limit = this.tagContentsCountPerPage;

    this.loading = true;

    zip(
      this.tagService.getTag(this.tagId),
      this.tagService.getTagContents(this.tagId, offset, limit)
    ).subscribe(
      ([tag, tagContents]) => {
        this.totalTagContentsCount = tagContents.total;
        this.tag = tag;
        this.tagContents = tagContents.items;

        this.loading = false;
      },
      (error) => {
        this.snackBarService.openMessage(`${error.error.error.message}`);
      }
    );
  }

  tagEditDialogOpen(): void {
    const data = {
      categoryId: this.tag.category.id,
      tag: this.tag,
    };

    const dialog = this.matDialog.open(TagEditDialogComponent, { data });
    dialog
      .afterClosed()
      .pipe(filter((result) => result === 'edit' || result === 'delete'))
      .subscribe((result) => {
        if (result === 'delete') {
          this.router.navigateByUrl(`/categories/${this.tag.category.id}`);
        } else if (result === 'edit') {
          this.snackBarService.openMessage('태그가 수정되었습니다.');
          this.loadTagPage(1);
        } else {
          this.loadTagPage(1);
        }
      });
  }

  updateQueryParams(queryParams: any): void {
    this.router.navigate([], {
      queryParams,
      queryParamsHandling: 'merge',
    });
  }

  changeTagRelationship(status: boolean): void {
    if (status) {
      this.relationshipService.update(this.tagId, 'likes').subscribe(() => {
        this.watchTag = status;
      });
    } else {
      this.relationshipService.delete(this.tagId, 'likes').subscribe(() => {
        this.watchTag = status;
      });
    }
  }

  pageChanged(page: number /* zero based */): void {
    this.updateQueryParams({ page: page + 1 });
  }

  taggingDialogOpen(): void {
    const dialog = this.matDialog.open(TaggingDialogComponent, {
      data: {
        id: this.tag.id,
        item: this.tag,
        relationItems: (this.tag.tags as any[]).concat(this.tagContents),
      },
    });

    dialog
      .afterClosed()
      .pipe(filter((ok) => ok))
      .subscribe(() => {
        this.loadTagPage(1);
      });
  }

  deleteItem(subscribeItem: any): void {
    let id;
    let subscribeItemId;

    if (subscribeItem.type) {
      id = subscribeItem.id;
      subscribeItemId = this.tag.id;
    } else {
      id = this.tag.id;
      subscribeItemId = subscribeItem.id;
    }

    const dialog = this.matDialog.open(ConfirmDialogComponent, {
      data: {
        title: '삭제',
        message: '삭제합니다. 계속하시겠습니까?',
        cancelable: true,
      },
    });

    dialog
      .afterClosed()
      .pipe(
        filter((ok) => ok),
        switchMap(() => this.relationshipService.deleteRelation(
            id,
            subscribeItemId,
            'tags'
          ))
      )
      .subscribe(
        () => {
          this.snackBarService.openMessage('삭제되었습니다.');
          this.loadTagPage(1);
        },
        (error: HttpErrorResponse) => {
          this.snackBarService.openMessage(
            `삭제하실 수 없습니다. ${error.error.error.message}`
          );
        }
      );
  }

  copy(): void {
    this.isCopied = true;
    setTimeout(() => {
      this.isCopied = false;
    }, 2000);
  }
}
