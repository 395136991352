import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { debounceTime, distinctUntilChanged } from 'rxjs/operators';

import { environment } from '../../environments/environment';
import { TagService } from './tag.service';
import { ContentsType } from '../models/contents.model';
@Injectable({
  providedIn: 'root',
})
export class SearchService {
  private _searchText: BehaviorSubject<any> = new BehaviorSubject(undefined);
  public readonly searchText: Observable<any> = this._searchText
    .asObservable()
    .pipe(debounceTime(500))
    .pipe(distinctUntilChanged());

  constructor(private http: HttpClient, private tagService: TagService) {}

  changeSearchText(searchText: string): void {
    this._searchText.next(searchText);
  }

  search(type: ContentsType, offset: number, limit: number, q: string) {
    const params: any = { offset, limit, q };

    if (type === 'tag') {
      return this.tagService.getAll(offset, limit, q);
    }

    return this.http.get<any>(`${environment.apiEndpoint}/contents/${type}`, {
      params,
      withCredentials: true,
    });
  }
}
