import { Component, Input, OnInit, EventEmitter, Output } from '@angular/core';

import { Tag } from '../../models/tag.model';

@Component({
  selector: 'app-suggestion-tag-list',
  templateUrl: './suggestion-tag-list.component.html',
  styleUrls: ['./suggestion-tag-list.component.scss'],
})
export class SuggestionTagListComponent implements OnInit {
  @Input() items: Tag[];
  @Input() selectedItems: Tag[];
  @Input() loading: Boolean;
  @Input() link: string;

  @Output() clickItem = new EventEmitter<{ isAdd: boolean; item: Tag }>();

  constructor() {}

  ngOnInit(): void {}

  initCheckboxValue(item: Tag): boolean {
    return !!this.selectedItems.find((element) => element.id === item.id);
  }

  onCheckboxChange(checked: boolean, item: Tag): void {
    if (checked) {
      this.clickItem.next({ isAdd: true, item });
    } else {
      this.clickItem.next({ isAdd: false, item });
    }
  }
}
