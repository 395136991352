<div mat-dialog-title>
  <div>카테고리 추가</div>
  <button mat-icon-button mat-dialog-close>
    <mat-icon class="close-icon">close</mat-icon>
  </button>
</div>
<div mat-dialog-content>
  <form
    [formGroup]="categoryPropertyFormGroup"
    *ngIf="categoryPropertyFormGroup"
  >
    <mat-form-field class="full-width">
      <mat-label>이름</mat-label>
      <input matInput formControlName="title" />
    </mat-form-field>
    <mat-form-field class="full-width">
      <mat-label>설명</mat-label>
      <textarea
        matInput
        formControlName="description"
        matTextareaAutosize
        matAutosizeMaxRows="3"
      ></textarea>
    </mat-form-field>
    <mat-form-field class="full-width">
      <mat-label>아이콘</mat-label>
      <input matInput formControlName="icon" />
      <mat-error
        *ngIf="categoryPropertyFormGroup.controls.icon.hasError('iconError')"
        >SVG 아이콘을 넣어주세요.</mat-error
      >
    </mat-form-field>
  </form>
</div>
<div class="buttons">
  <button
    mat-raised-button
    color="accent"
    (click)="save()"
    [disabled]="categoryPropertyFormGroup.invalid"
  >
    {{ '추가' }}
  </button>
</div>
