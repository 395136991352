<div class="page">
  <div class="tabs">
    <a
      class="tab"
      *ngFor="let tab of tabs"
      [class.active]="tab === selectedTab"
      (click)="updateQueryParams({ tab: tab, page: '1' })"
      >{{ tab }}</a
    >
  </div>
  <mat-divider></mat-divider>
  <app-list [items]="items" [loading]="loading"></app-list>
  <div class="paginator" *ngIf="items?.length > 0 && !loading">
    <app-paginator
      [page]="page - 1"
      [totalItemCount]="totalItemCount"
      [itemCountPerPage]="itemCountPerPage"
      (pageChange)="pageChanged($event)"
    ></app-paginator>
  </div>
</div>
