<div class="empty-msg" *ngIf="items?.length === 0 && !loading">
  목록이 비어있습니다.
</div>
<ng-container *ngIf="items?.length > 0 && !loading">
  <mat-checkbox
    *ngFor="let item of items"
    class="list-item"
    (change)="onCheckboxChange($event.checked, item)"
    [checked]="initCheckboxValue(item)"
  >
    <div class="list-item-info">
      <div class="list-item-category" *ngIf="item.category">
        {{ item.category.title }}
      </div>
      <div class="list-item-title">
        <span class="list-item-code" *ngIf="item.code">
          &#91;{{ item.code }}&#93;
        </span>
        <span>{{ item.title }}</span>
      </div>
      <div class="list-item-description" *ngIf="item.description">
        {{ item.description }}
      </div>
    </div>
  </mat-checkbox>
</ng-container>
<div *ngIf="loading" class="loader" [class.empty]="!items">
  <mat-spinner class="spinner" [diameter]="40" color="accent"></mat-spinner>
</div>
