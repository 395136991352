import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

import { environment } from '../../environments/environment';
import { Contents, ContentsType } from '../models/contents.model';
import { QueryResult } from '../models/query-result.model';

@Injectable({
  providedIn: 'root',
})
export class ContentsService {
  constructor(private http: HttpClient) {}

  getAllContents(
    type: ContentsType,
    offset: number,
    limit: number
  ): Observable<QueryResult<Contents>> {
    const params: any = { offset, limit };

    return this.http.get<QueryResult<Contents>>(
      `${environment.apiEndpoint}/contents/${type}`,
      {
        params,
        withCredentials: true,
      }
    );
  }

  getContents(type: ContentsType, contentId: string): Observable<Contents> {
    return this.http.get<Contents>(
      `${environment.apiEndpoint}/contents/${type}/${contentId}`,
      {
        withCredentials: true,
      }
    );
  }

  getRelates(
    type: ContentsType,
    contentId: string,
    offset: number,
    limit: number
  ): Observable<QueryResult<Contents>> {
    const params: any = { offset, limit };

    return this.http.get<QueryResult<Contents>>(
      `${environment.apiEndpoint}/contents/${type}/${contentId}/contents`,
      {
        params,
        withCredentials: true,
      }
    );
  }
}
