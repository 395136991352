import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { Observable } from 'rxjs';

import { environment } from '../../environments/environment';
import { Category } from '../models/category.model';
import { QueryResult } from '../models/query-result.model';

@Injectable({
  providedIn: 'root',
})
export class CategoryService {
  constructor(private http: HttpClient) {}

  getAll(offset: number, limit: number): Observable<QueryResult<Category>> {
    const params: any = { offset, limit };

    return this.http.get<QueryResult<Category>>(
      `${environment.apiEndpoint}/categories`,
      { params, withCredentials: true }
    );
  }

  getCategory(categoryId: string): Observable<Category> {
    return this.http.get<Category>(
      `${environment.apiEndpoint}/categories/${categoryId}`,
      { withCredentials: true }
    );
  }

  create(category: Category): Observable<Category> {
    return this.http.post<Category>(
      `${environment.apiEndpoint}/categories`,
      category,
      { withCredentials: true }
    );
  }

  update(category: Category): Observable<Category> {
    return this.http.put<Category>(
      `${environment.apiEndpoint}/categories/${category.id}`,
      category,
      { withCredentials: true }
    );
  }

  delete(categoryId): Observable<void> {
    return this.http.delete<void>(
      `${environment.apiEndpoint}/categories/${categoryId}`,
      { withCredentials: true }
    );
  }
}
