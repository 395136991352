<div class="page">
  <div class="search-text">&#39;{{ queryFilter }}&#39;의 검색 결과</div>
  <div class="tabs">
    <a
      *ngFor="let tab of tabs"
      [class.active]="selectedTab === tab"
      (click)="updateQueryParams({ tab: tab, page: '1' })"
      >{{ tab | titlecase }}
      <ng-container *ngIf="count">&#40;{{ count[tab] }}&#41;</ng-container>
    </a>
  </div>
  <mat-divider></mat-divider>
  <app-list [items]="items" [loading]="loading"></app-list>
  <div class="paginator" *ngIf="items?.length > 0 && !loading">
    <app-paginator
      [page]="page - 1"
      [totalItemCount]="totalItemCount"
      [itemCountPerPage]="itemCountPerPage"
      (pageChange)="pageChanged($event)"
    ></app-paginator>
  </div>
</div>
