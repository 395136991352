import * as Url from 'url-parse';

import { environment } from '../../environments/environment';

export class Utils {
  static createLoginUrl(redirectTo: string, params?: any): string {
    const loginUrl = new Url(`${environment.bukOrigin}/login`);

    loginUrl.set('query', Object.assign({
      redirect_to: redirectTo,
    }, params));

    return loginUrl.toString();
  }

  static createLogoutUrl(redirectTo: string): string {
    const logoutUrl = new Url(`${environment.bukOrigin}/logout`);

    logoutUrl.set('query', {
      redirect_to: redirectTo,
    });

    return logoutUrl.toString();
  }
}
