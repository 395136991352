import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Subject } from 'rxjs';
import { debounceTime } from 'rxjs/operators';
import { HttpErrorResponse } from '@angular/common/http';

import { SearchService } from '../../services/search.service';
import { RelationshipService } from '../../services/relationship.service';
import { SnackBarService } from '../../services/snack.bar.service';
import { TagService } from '../../services/tag.service';

import { Tag } from '../../models/tag.model';
import { ContentsType } from '../../models/contents.model';

@Component({
  selector: 'app-tagging-dialog',
  templateUrl: './tagging-dialog.component.html',
  styleUrls: ['./tagging-dialog.component.scss'],
})
export class TaggingDialogComponent implements OnInit {
  public searchChange$: Subject<string> = new Subject<string>();

  public count: {
    book: number;
    post: number;
    channel: number;
    writer: number;
    tag: number;
  };
  public suggestedTags: Tag[];
  public relationItems: any;
  private id: string;
  public item: any;
  public tabs = [
    'tag',
    'book',
    'post',
    'channel',
    'writer',
    'imprint',
    'library',
    'magazine',
  ];
  public tab: ContentsType = 'tag';
  public searchText: string;
  public isDirty = false;
  public loading: boolean;

  public relationes: Array<Tag>;
  public selectedRelation = 'tags';
  public selectedItems = [];
  private relationCategoryId = '200.0.100.136';

  constructor(
    public dialogRef: MatDialogRef<TaggingDialogComponent>,
    @Inject(MAT_DIALOG_DATA)
    public data: {
      id: string;
      item: any;
      relationItems: any;
    },
    private relationshipService: RelationshipService,
    private snackBarService: SnackBarService,
    private searchService: SearchService,
    private tagService: TagService
  ) {
    this.id = data.id;
    this.item = data.item;
    this.relationItems = data.relationItems;
  }

  ngOnInit(): void {
    this.dialogRef.updateSize('700px');
    this.dialogRef.disableClose = true;

    this.searchChange$.pipe(debounceTime(300)).subscribe(() => {
      this.loadItems(this.tab);
    });

    this.tagService
      .getTags(this.relationCategoryId, 0, 100)
      .subscribe((tags) => {
        this.relationes = tags.items;
      });
  }

  loadItems(tab: ContentsType): void {
    const offset = 0;
    const limit = 30;

    this.tab = tab;
    this.loading = true;

    this.searchService
      .search(tab, offset, limit, this.searchText)
      .subscribe((result) => {
        this.suggestedTags = result.items.filter((item) => item.id !== this.id);
        this.count = result.count;

        this.loading = false;
      });
  }

  updateAddedItems(subscribeItem: any): void {
    if (this.item.id === subscribeItem.item.id) {
      this.snackBarService.openMessage(`추가할 수 없습니다.`);
      return;
    }

    if (subscribeItem.isAdd) {
      this.selectedItems.push(subscribeItem.item);
    } else {
      this.selectedItems = this.selectedItems.filter(
        (item) => item.id !== subscribeItem.item.id
      );
    }
  }

  connect(): void {
    this.selectedItems.forEach((item) => {
      this.relationshipService
        .relate(this.id, item.id, this.selectedRelation, [this.item, item])
        .subscribe(
          () => {
            this.snackBarService.openMessage('추가되었습니다.');
            this.isDirty = true;
            this.dialogRef.close(true);
          },
          (error: HttpErrorResponse) => {
            this.snackBarService.openMessage(
              `태그를 추가 할 수 없습니다. ${error.error.error.message}`
            );

            const index = this.relationItems.findIndex(
              (element) => element.id === item.id
            );
            this.relationItems.splice(index, 1);
          }
        );
    });
  }
}
