import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';

import { Utils } from '../../misc/utils';
import { Observable, Subject } from 'rxjs';
import { first, map, takeUntil } from 'rxjs/operators';
import { AuthHelper } from '../../auth-helper';

import { UserService } from '../../services/user.service';
import { SearchService } from '../../services/search.service';

@Component({
  selector: 'app-nav',
  templateUrl: './nav.component.html',
  styleUrls: ['./nav.component.scss'],
})
export class NavComponent implements OnInit {
  private unsubscriber: Subject<void> = new Subject<void>();

  public readonly logoutUrl = Utils.createLogoutUrl(location.origin);
  public isLoggedIn$: Observable<boolean>;
  public home: 'tags' | 'contents';

  public queryFilter: string;
  private page = 1;
  private tab = 'book';

  constructor(
    private userService: UserService,
    private router: Router,
    private searchService: SearchService
  ) {
    this.isLoggedIn$ = this.userService.user$.pipe(
      first(),
      map((user) => !!user)
    );

    this.searchService.searchText
      .pipe(takeUntil(this.unsubscriber))
      .subscribe((text) => {
        this.queryFilter = text;
      });
  }

  ngOnInit(): void {}

  logout(): void {
    AuthHelper.goToLogout(location.href);
  }

  goTosearchPage(): void {
    this.router.navigate(['search'], {
      queryParams: { page: this.page, tab: this.tab, q: this.queryFilter },
      queryParamsHandling: 'merge',
    });

    this.setSearchText(this.queryFilter);
  }

  setSearchText(text: string): void {
    this.searchService.changeSearchText(text);
  }
}
