import { Component, OnInit, Inject } from '@angular/core';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA, MatDialog } from '@angular/material/dialog';
import { HttpErrorResponse } from '@angular/common/http';
import { filter, switchMap } from 'rxjs/operators';

import { SnackBarService } from '../../services/snack.bar.service';
import { TagService } from '../../services/tag.service';
import { Tag } from '../../models/tag.model';

import { ConfirmDialogComponent } from '../../dialogs/confirm-dialog/confirm-dialog.component';

@Component({
  selector: 'app-tag-edit-dialog',
  templateUrl: './tag-edit-dialog.component.html',
  styleUrls: ['./tag-edit-dialog.component.scss'],
})
export class TagEditDialogComponent implements OnInit {
  public tagPropertyFormGroup: FormGroup;

  constructor(
    public dialogRef: MatDialogRef<TagEditDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: { categoryId: string; tag: Tag },
    private tagService: TagService,
    private snackBarService: SnackBarService,
    private matDialog: MatDialog
  ) {}

  ngOnInit(): void {
    this.dialogRef.updateSize('420px');
    this.dialogRef.disableClose = true;

    this.tagPropertyFormGroup = new FormGroup({
      id: new FormControl(this.data.tag ? this.data.tag.id : ''),
      title: new FormControl(this.data.tag ? this.data.tag.title : '', [
        Validators.required,
      ]),
      description: new FormControl(
        this.data.tag ? this.data.tag.description : ''
      ),
    });
  }

  edit(): void {
    this.tagService.update(this.tagPropertyFormGroup.value).subscribe(
      () => {
        this.dialogRef.close('edit');
      },
      (error: HttpErrorResponse) => {
        this.snackBarService.openMessage(
          `수정되지 않음 ${error.error.error.message}`
        );
      }
    );
  }

  confirmDialogOpen(): void {
    const dialog = this.matDialog.open(ConfirmDialogComponent, {
      data: {
        title: '태그 삭제',
        message: '태그를 삭제합니다. 계속하시겠습니까?',
        cancelable: true,
      },
    });

    dialog
      .afterClosed()
      .pipe(
        filter((ok) => ok),
        switchMap(() => this.tagService.delete(this.data.tag.id))
      )
      .subscribe(
        () => {
          this.snackBarService.openMessage('태그가 삭제되었습니다.');
          this.dialogRef.close('delete');
        },
        (error: HttpErrorResponse) => {
          this.snackBarService.openMessage(
            `태그를 삭제 할 수 없습니다. ${error.error.error.message}`
          );
        }
      );
  }
}
