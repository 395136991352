import { Component, OnInit, Inject } from '@angular/core';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { HttpErrorResponse } from '@angular/common/http';

import { SnackBarService } from '../../services/snack.bar.service';
import { TagService } from '../../services/tag.service';

@Component({
  selector: 'app-tag-add-dialog',
  templateUrl: './tag-add-dialog.component.html',
  styleUrls: ['./tag-add-dialog.component.scss'],
})
export class TagAddDialogComponent implements OnInit {
  public tagPropertyFormGroup: FormGroup;

  constructor(
    public dialogRef: MatDialogRef<TagAddDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: { categoryId: string },
    private tagService: TagService,
    private snackBarService: SnackBarService
  ) {}

  ngOnInit(): void {
    this.dialogRef.updateSize('420px');
    this.dialogRef.disableClose = true;

    this.tagPropertyFormGroup = new FormGroup({
      id: new FormControl(''),
      title: new FormControl('', [Validators.required]),
      description: new FormControl(''),
    });
  }

  save(): void {
    this.tagService
      .create(this.data.categoryId, this.tagPropertyFormGroup.value)
      .subscribe(
        () => {
          this.dialogRef.close('add');
        },
        (error: HttpErrorResponse) => {
          this.snackBarService.openMessage(
            `추가되지 않음 ${error.error.error.message}`
          );
        }
      );
  }
}
