import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { HttpClientModule } from '@angular/common/http';
import {
  FormsModule,
  COMPOSITION_BUFFER_MODE,
  ReactiveFormsModule,
} from '@angular/forms';
import { MatAutocompleteModule } from '@angular/material/autocomplete';
import { MatButtonModule } from '@angular/material/button';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatChipsModule } from '@angular/material/chips';
import { MatDialogModule } from '@angular/material/dialog';
import { MatDividerModule } from '@angular/material/divider';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { MatMenuModule } from '@angular/material/menu';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatRadioModule } from '@angular/material/radio';
import { MatSelectModule } from '@angular/material/select';
import { MAT_SNACK_BAR_DEFAULT_OPTIONS, MatSnackBarModule } from '@angular/material/snack-bar';
import { MatSortModule } from '@angular/material/sort';
import { MatTabsModule } from '@angular/material/tabs';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { JwtModule } from '@auth0/angular-jwt';
import { AuthHelper } from './auth-helper';

// 3rd party modules
import { ImageCropperModule } from 'ngx-image-cropper';
import { ClipboardModule } from 'ngx-clipboard';

// routing module
import { AppRoutingModule } from './app-routing.module';

// pages
import { AppComponent } from './app.component';
import { ContentsHomePageComponent } from './pages/contents-home-page/contents-home-page.component';
import { ContentsPageComponent } from './pages/contents-page/contents-page.component';
import { TagsHomePageComponent } from './pages/tags-home-page/tags-home-page.component';
import { CategoryPageComponent } from './pages/category-page/category-page.component';
import { TagPageComponent } from './pages/tag-page/tag-page.component';
import { SearchPageComponent } from './pages/search-page/search-page.component';

// dialogs
import { ConfirmDialogComponent } from './dialogs/confirm-dialog/confirm-dialog.component';
import { CategoryEditDialogComponent } from './dialogs/category-edit-dialog/category-edit-dialog.component';
import { TagEditDialogComponent } from './dialogs/tag-edit-dialog/tag-edit-dialog.component';
import { ImageUploadDialogComponent } from './dialogs/image-upload-dialog/image-upload-dialog.component';
import { TaggingDialogComponent } from './dialogs/tagging-dialog/tagging-dialog.component';
import { CategoryAddDialogComponent } from './dialogs/category-add-dialog/category-add-dialog.component';
import { TagAddDialogComponent } from './dialogs/tag-add-dialog/tag-add-dialog.component';

// components
import { PaginatorComponent } from './components/paginator/paginator.component';
import { NavComponent } from './components/nav/nav.component';
import { ListHeaderComponent } from './components/list-header/list-header.component';
import { ListComponent } from './components/list/list.component';
import { SuggestionTagListComponent } from './components/suggestion-tag-list/suggestion-tag-list.component';

// pipe
import { SafePipe } from './pipe/safe.pipe';

export function tokenGetter() {
  return AuthHelper.retriveAccessToken();
}

@NgModule({
  declarations: [
    // pages
    AppComponent,
    ContentsHomePageComponent,
    ContentsPageComponent,
    TagsHomePageComponent,
    CategoryPageComponent,
    TagPageComponent,
    SearchPageComponent,
    // dialog
    ConfirmDialogComponent,
    CategoryEditDialogComponent,
    TagEditDialogComponent,
    ImageUploadDialogComponent,
    TaggingDialogComponent,
    CategoryAddDialogComponent,
    TagAddDialogComponent,
    // components
    PaginatorComponent,
    NavComponent,
    ListHeaderComponent,
    ListComponent,
    SuggestionTagListComponent,
    // pipe
    SafePipe,
  ],
  imports: [
    // angular modules
    BrowserModule,
    BrowserAnimationsModule,
    HttpClientModule,
    FormsModule,
    ReactiveFormsModule,
    // angular modules - material
    MatFormFieldModule,
    MatInputModule,
    MatSnackBarModule,
    MatDialogModule,
    MatProgressSpinnerModule,
    MatMenuModule,
    MatButtonModule,
    MatIconModule,
    MatSortModule,
    MatAutocompleteModule,
    MatChipsModule,
    MatTabsModule,
    MatDividerModule,
    MatRadioModule,
    MatSelectModule,
    MatCheckboxModule,
    // 3rd party modules
    ImageCropperModule,
    ClipboardModule,
    // routing module
    AppRoutingModule,
    JwtModule.forRoot({
      config: {
        tokenGetter,
        whitelistedDomains: ['buk.io'],
      },
    }),
  ],
  providers: [
    {
      provide: COMPOSITION_BUFFER_MODE,
      useValue: false,
    },
    {
      provide: MAT_SNACK_BAR_DEFAULT_OPTIONS,
      useValue: {
        horizontalPosition: 'right',
        verticalPosition: 'top',
        duration: 2500,
      },
    },
  ],
  entryComponents: [
    ConfirmDialogComponent,
    CategoryEditDialogComponent,
    TagEditDialogComponent,
    ImageUploadDialogComponent,
    TaggingDialogComponent,
    CategoryAddDialogComponent,
    TagAddDialogComponent,
  ],
  bootstrap: [AppComponent],
})
export class AppModule {}
