<div class="page" *ngIf="tag">
  <app-list-header>
    <div class="row parents">
      <a class="parent" routerLink="/categories/{{ tag.category.id }}">{{
        tag.category.title
      }}</a>
      <ng-container *ngIf="tag.parents.length > 0">
        <a
          class="parent"
          *ngFor="let parent of tag.parents"
          routerLink="/tags/{{ parent.id }}"
          >&#91;{{ parent.code }}&#93; {{ parent.title }}</a
        >
      </ng-container>
    </div>
    <div class="row">
      <div class="title">
        <ng-container *ngIf="tag.code">
          <span>&#91;{{ tag.code }}&#93;</span>
        </ng-container>
        <span>{{ tag.title }}</span>
        <mat-icon
          class="icon pointer"
          (click)="tagEditDialogOpen()"
          *ngIf="isAdmin"
        >
          settings
        </mat-icon>
      </div>
    </div>
    <div class="row description" *ngIf="tag.description">
      <div>{{ tag.description }}</div>
      <div class="see-also-wrapper" *ngIf="tag.seeAlso?.length > 0">
        See Also :
        <a
          *ngFor="let item of tag.seeAlso"
          routerLink="/categories/{{ tag.category.id }}"
          [queryParams]="{ q: item }"
          queryParamsHandling="merge"
          class="see-also"
          >{{ item }}</a
        >
      </div>
    </div>
    <div
      class="row text-secondary tag-id"
      ngxClipboard
      (cbOnSuccess)="copy()"
      [cbContent]="tag.id"
    >
      <span>{{ tag.id }}</span>
      <span class="copy-noti" *ngIf="isCopied">클립보드에 복사되었습니다.</span>
    </div>
    <div class="row relationTag" *ngIf="tag.tags.length > 0">
      <mat-chip-list>
        <mat-chip
          *ngFor="let relationTag of tag.tags"
          (removed)="deleteItem(relationTag)"
          routerLink="/tags/{{ relationTag.id }}"
          >{{ relationTag.category.title }} - {{ relationTag.title }}
          <mat-icon *ngIf="isAdmin" matChipRemove>cancel</mat-icon></mat-chip
        >
      </mat-chip-list>
    </div>
    <div class="row button-wrapper right">
      <button
        mat-stroked-button
        color="accent"
        *ngIf="!watchTag"
        (click)="changeTagRelationship(true)"
      >
        <mat-icon class="visibility-icon">visibility</mat-icon>
        관심태그등록
      </button>
      <button
        mat-stroked-button
        color="warn"
        *ngIf="watchTag"
        (click)="changeTagRelationship(false)"
      >
        <mat-icon class="visibility-icon">visibility_off</mat-icon>

        관심태그해제
      </button>
      <button
        mat-raised-button
        color="accent"
        *ngIf="isAdmin"
        (click)="taggingDialogOpen()"
      >
        <mat-icon>add</mat-icon>
        Tagging
      </button>
    </div>
  </app-list-header>
  <mat-divider></mat-divider>
  <app-list
    *ngIf="tagContents"
    [items]="tagContents"
    [isDeleted]="true"
    [isAdmin]="isAdmin"
    [loading]="loading"
    (delete)="deleteItem($event)"
  ></app-list>
  <div class="paginator" *ngIf="!loading && tagContents?.length > 0">
    <app-paginator
      [page]="page - 1"
      [totalItemCount]="totalTagContentsCount"
      [itemCountPerPage]="tagContentsCountPerPage"
      (pageChange)="pageChanged($event)"
    ></app-paginator>
  </div>
</div>
