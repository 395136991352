import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';

import { environment } from '../../environments/environment';
@Injectable({
  providedIn: 'root',
})
export class RelationshipService {
  constructor(private http: HttpClient) {}

  query(id: string, relationship: string): Observable<any> {
    return this.http.get<any>(`https://graph.buk.io/${id}/${relationship}`, {
      withCredentials: true,
    });
  }

  update(id: string, relationship: string): Observable<any> {
    return this.http.put<any>(
      `https://graph.buk.io/${id}/${relationship}`,
      null,
      { withCredentials: true }
    );
  }

  delete(id: string, relationship: string): Observable<void> {
    return this.http.delete<void>(
      `https://graph.buk.io/${id}/${relationship}`,
      { withCredentials: true }
    );
  }

  relate(
    id: string,
    subscribeItemId: string,
    relationship: string,
    info?: Array<any>
  ): Observable<any> {
    return this.http.put<any>(
      `${environment.apiEndpoint}/${id}/${relationship}/${subscribeItemId}`,
      info,
      { withCredentials: true }
    );
  }

  deleteRelation(id: string, subscribeItemId: string, relationship: string) {
    return this.http.delete<void>(
      `${environment.apiEndpoint}/${id}/${relationship}/${subscribeItemId}`,
      { withCredentials: true }
    );
  }
}
