import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';

// Gards
import { AuthGuard } from './guards/auth.guard';

// Page Components
import { TagsHomePageComponent } from './pages/tags-home-page/tags-home-page.component';
import { ContentsHomePageComponent } from './pages/contents-home-page/contents-home-page.component';
import { ContentsPageComponent } from './pages/contents-page/contents-page.component';
import { CategoryPageComponent } from './pages/category-page/category-page.component';
import { TagPageComponent } from './pages/tag-page/tag-page.component';
import { SearchPageComponent } from './pages/search-page/search-page.component';

const routes: Routes = [
  {
    path: '',
    component: TagsHomePageComponent,
    canActivate: [AuthGuard],
  },
  {
    path: 'tags',
    component: TagsHomePageComponent,
    canActivate: [AuthGuard],
  },
  {
    path: 'interlink',
    component: ContentsHomePageComponent,
    canActivate: [AuthGuard],
  },
  {
    path: 'interlink/:type/:contentsId',
    component: ContentsPageComponent,
    canActivate: [AuthGuard],
  },
  {
    path: 'search',
    component: SearchPageComponent,
    canActivate: [AuthGuard],
  },
  {
    path: 'categories/:categoryId',
    component: CategoryPageComponent,
    canActivate: [AuthGuard],
  },
  {
    path: 'tags/:tagId',
    component: TagPageComponent,
    canActivate: [AuthGuard],
  },
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule],
})
export class AppRoutingModule {}
