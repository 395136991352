import { Component, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { ActivatedRoute, Router } from '@angular/router';
import { map, filter, takeUntil } from 'rxjs/operators';
import { HttpErrorResponse } from '@angular/common/http';
import { Subject } from 'rxjs';

import { UserService } from '../../services/user.service';
import { CategoryService } from '../../services/category.service';
import { SnackBarService } from '../../services/snack.bar.service';
import { Category } from '../../models/category.model';

import { CategoryAddDialogComponent } from '../../dialogs/category-add-dialog/category-add-dialog.component';

@Component({
  selector: 'app-tags-home-page',
  templateUrl: './tags-home-page.component.html',
  styleUrls: ['./tags-home-page.component.scss'],
})
export class TagsHomePageComponent implements OnInit {
  private unsubscriber: Subject<void> = new Subject<void>();

  public categoryCountPerPage = 10;
  public totalCategoryCount: number;
  public page: number;

  public categories: Category[];

  public loading: boolean;
  public isAdmin: boolean;

  constructor(
    private matDialog: MatDialog,
    private router: Router,
    private activatedRoute: ActivatedRoute,
    private userService: UserService,
    private categoryService: CategoryService,
    private snackBarService: SnackBarService
  ) {}

  ngOnInit(): void {
    this.activatedRoute.queryParams
      .pipe(
        map((queryParams) => ({
            page: queryParams.page
              ? parseInt(queryParams.page, undefined)
              : undefined,
          }))
      )
      .subscribe(({ page }) => {
        const checkedPage = page || 1;

        if (page !== checkedPage) {
          const newQueryParams: any = {
            page: checkedPage,
          };
          this.router.navigate([], {
            queryParams: newQueryParams,
            replaceUrl: true,
          });
          return;
        }

        if (this.page !== page) {
          this.loadCategories(page);
        }

        this.page = page;
      });

    this.userService.user$
      .pipe(takeUntil(this.unsubscriber))
      .subscribe((user) => (this.isAdmin = user.isAdmin));
  }

  loadCategories(page: number): void {
    const offset = (page - 1) * this.categoryCountPerPage;
    const limit = this.categoryCountPerPage;

    this.loading = true;

    this.categoryService.getAll(offset, limit).subscribe(
      (categories) => {
        this.totalCategoryCount = categories.total;
        this.categories = categories.items;

        if (page > 1 && categories.items.length === 0) {
          this.updateQueryParams({ page: page - 1 });
        }

        this.loading = false;
      },
      (error: HttpErrorResponse) => {
        this.snackBarService.openMessage(
          `오류가 발생했습니다. ${error.error.error.message}`
        );
      }
    );
  }

  categoryAddDialogOpen(): void {
    const dialog = this.matDialog.open(CategoryAddDialogComponent);
    dialog
      .afterClosed()
      .pipe(filter((ok) => ok))
      .subscribe(() => {
        this.loadCategories(1);
        this.snackBarService.openMessage('카테고리가 저장되었습니다.');
      });
  }

  pageChanged(page: number /* zero based */): void {
    this.updateQueryParams({ page: page + 1 });
  }

  updateQueryParams(queryParams: any): void {
    this.router.navigate([], {
      queryParams,
      queryParamsHandling: 'merge',
    });
  }
}
