<div class="page" *ngIf="contents">
  <app-list-header>
    <div class="row contents-header">
      <div
        class="image"
        [ngClass]="type"
        *ngIf="contents.image_url"
        [style.background-image]="'url(' + contents.image_url + ')'"
      ></div>
      <div class="info-text">
        <div
          class="type"
          [routerLink]="['/interlink']"
          [queryParams]="{ tab: contents.type, page: 1 }"
        >
          {{ contents.type }}
        </div>
        <div class="title">
          <span>{{ contents.title }}</span>
          <a href="https://buk.io/{{ contents.id }}" target="_blank"
            ><mat-icon class="icon" color="accent"> open_in_new </mat-icon></a
          >
        </div>
        <div>
          {{ contents.description }}
        </div>
      </div>
    </div>
    <div class="row relationTag">
      <mat-chip-list>
        <ng-container *ngIf="contents.tags.length > 0">
          <mat-chip
            *ngFor="let relationTag of contents.tags"
            (removed)="deleteItem(relationTag)"
            [routerLink]="['/tags', relationTag.id]"
            >{{ relationTag.category.title }} - {{ relationTag.title }}
            <mat-icon *ngIf="isAdmin" matChipRemove>cancel</mat-icon>
          </mat-chip>
        </ng-container>
      </mat-chip-list>
    </div>
    <div class="row button-wrapper right">
      <button
        mat-raised-button
        color="accent"
        *ngIf="isAdmin"
        (click)="connect()"
      >
        <mat-icon>add</mat-icon>
        Connect
      </button>
    </div>
  </app-list-header>
  <mat-divider></mat-divider>
  <app-list
    [items]="relationItems"
    [loading]="loading"
    [isDeleted]="true"
    [isAdmin]="isAdmin"
    (delete)="deleteItem($event)"
  ></app-list>
  <div class="paginator" *ngIf="!loading && relationItems?.length > 0">
    <app-paginator
      [page]="page - 1"
      [totalItemCount]="totalContentsCount"
      [itemCountPerPage]="contentsCountPerPage"
      (pageChange)="pageChanged($event)"
    ></app-paginator>
  </div>
</div>
