import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';

@Component({
  selector: 'app-paginator',
  templateUrl: './paginator.component.html',
  styleUrls: ['./paginator.component.scss'],
})
export class PaginatorComponent implements OnInit {
  private _page = 0;
  public pageCount = 0;
  public numbers: number[];

  private _totalItemCount: number;
  private _itemCountPerPage: number;

  public numbersPage = 0; // 0-9 => 0, 10-19 => 1
  @Input() numbersCountPerNumbersPage = 10;

  @Output() pageChange = new EventEmitter<number>();

  constructor() {}

  ngOnInit(): void {}

  @Input()
  set totalItemCount(count: number) {
    this._totalItemCount = count;
    this.init();
  }

  @Input()
  set itemCountPerPage(count: number) {
    this._itemCountPerPage = count;
    this.init();
  }

  @Input()
  set page(page: number) {
    this.setPage(page);
  }

  get page(): number {
    return this._page;
  }

  init(): void {
    if (
      isNaN(this._totalItemCount) ||
      isNaN(this._itemCountPerPage) ||
      this._totalItemCount < 1 ||
      this._itemCountPerPage < 1
    ) {
      this.pageCount = 0;
    } else {
      this.pageCount = Math.ceil(this._totalItemCount / this._itemCountPerPage);
    }

    if (this.pageCount > 0) {
      this.numbers = Array(this.pageCount)
        .fill(null)
        .map((e, i) => i);
      this.numbersPage = 0;
    }
  }

  _setPage(index: number): void {
    if (this.page === index) {
      return;
    }

    this.setPage(index);
    this.pageChange.emit(index);
  }

  setPage(index: number): void {
    this._page = index;
    this.numbersPage = Math.floor(index / this.numbersCountPerNumbersPage);
  }

  get maxNumbersPage(): number {
    return Math.ceil(this.numbers.length / this.numbersCountPerNumbersPage) - 1;
  }

  setNumbersPage(direction: 'prev' | 'first' | 'next' | 'end') {
    switch (direction) {
      case 'prev':
        // this.numbersPage--;
        this._setPage(this.numbersCountPerNumbersPage * this.numbersPage - 1);
        break;
      case 'next':
        // this.numbersPage++;
        this._setPage(this.numbersCountPerNumbersPage * (this.numbersPage + 1));
        break;
      case 'first':
        // this.numbersPage = 0;
        this._setPage(0);
        break;
      case 'end':
        // this.numbersPage = this.maxNumbersPage;
        this._setPage(this.numbers.length - 1);
        break;
    }
  }

  isVisiblePage(page: number): boolean {
    return (
      page >= this.numbersCountPerNumbersPage * this.numbersPage && // min
      page < this.numbersCountPerNumbersPage * (this.numbersPage + 1)
    ); // max
  }
}
