import { environment } from '../environments/environment';
import * as Url from 'url-parse';

const generateNonce = (): string => {
  const charset = '0123456789ABCDEFGHIJKLMNOPQRSTUVXYZabcdefghijklmnopqrstuvwxyz-._~';
  const result = [];
  (window.crypto.getRandomValues(new Uint8Array(32)) as Uint8Array).forEach((c) => {
    result.push(charset[c % charset.length]);
  });
  return result.join('');
};

export class AuthHelper {
  static retriveAccessToken(): string {
    return localStorage.getItem('access_token');
  }

  static processCallback() {
    const currentUrl = new Url(location.href);

    /* nothing to process */
    if (!currentUrl.hash) {
      return;
    }

    const parsedHash = Url.qs.parse(currentUrl.hash.substring(1)) as any;
    const savedState = localStorage.getItem('oauth_state');

    /* invalid redirect, skip setting an access token */
    if (!parsedHash.access_token || !parsedHash.state ||
      !savedState || parsedHash.state !== savedState) {
      return;
    }
    localStorage.setItem('access_token', parsedHash.access_token);

    const state = localStorage.getItem(savedState)
      ? JSON.parse(localStorage.getItem(savedState))
      : {};

    if (state.redirectUrl) {
      location.replace(state.redirectUrl);
    } else {
      location.replace(currentUrl.set('hash', undefined).toString());
    }

    /* clear */
    localStorage.removeItem('oauth_state');
    localStorage.removeItem(savedState);
  }

  static goToLogout(redirectUrl?: string) {
    localStorage.removeItem('access_token');

    const logoutUrl = new Url(`${environment.bukOrigin}/logout`);
    location.href = logoutUrl.toString();
  }
}
