import { Component, OnInit } from '@angular/core';
import {
  FormGroup,
  FormControl,
  Validators,
  ValidatorFn,
  AbstractControl,
} from '@angular/forms';
import { MatDialogRef } from '@angular/material/dialog';
import { HttpErrorResponse } from '@angular/common/http';

import { CategoryService } from '../../services/category.service';
import { SnackBarService } from '../../services/snack.bar.service';

export function iconValidator(): ValidatorFn {
  return (control: AbstractControl): { [key: string]: any } | null => {
    if (control.value === '' || /\<\/svg\>$/.test(control.value)) {
      return null;
    } else {
      return { iconError: true };
    }
  };
}

@Component({
  selector: 'app-category-add-dialog',
  templateUrl: './category-add-dialog.component.html',
  styleUrls: ['./category-add-dialog.component.scss'],
})
export class CategoryAddDialogComponent implements OnInit {
  public categoryPropertyFormGroup: FormGroup;

  constructor(
    public dialogRef: MatDialogRef<CategoryAddDialogComponent>,
    private categoryService: CategoryService,
    private snackBarService: SnackBarService
  ) {}

  ngOnInit(): void {
    this.dialogRef.updateSize('420px');
    this.dialogRef.disableClose = true;

    this.categoryPropertyFormGroup = new FormGroup({
      id: new FormControl(''),
      title: new FormControl('', [Validators.required]),
      description: new FormControl(''),
      icon: new FormControl('', [iconValidator()]),
    });
  }

  save(): void {
    this.categoryService.create(this.categoryPropertyFormGroup.value).subscribe(
      () => {
        this.dialogRef.close('add');
      },
      (error: HttpErrorResponse) => {
        this.snackBarService.openMessage(
          `추가되지 않음 ${error.error.error.message}`
        );
      }
    );
  }
}
