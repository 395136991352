<div mat-dialog-title>
  <div>태그 수정</div>
  <button mat-icon-button mat-dialog-close>
    <mat-icon class="close-icon">close</mat-icon>
  </button>
</div>
<div mat-dialog-content>
  <form [formGroup]="tagPropertyFormGroup" *ngIf="tagPropertyFormGroup">
    <mat-form-field class="full-width">
      <mat-label>Title</mat-label>
      <input matInput formControlName="title" />
    </mat-form-field>
    <mat-form-field class="full-width">
      <mat-label>Description</mat-label>
      <textarea
        matInput
        formControlName="description"
        matTextareaAutosize
        matAutosizeMaxRows="3"
      ></textarea>
    </mat-form-field>
  </form>
</div>
<div class="buttons">
  <button mat-button color="warn" (click)="confirmDialogOpen()">삭제</button>
  <button
    mat-raised-button
    color="accent"
    (click)="edit()"
    [disabled]="tagPropertyFormGroup.invalid || !tagPropertyFormGroup.dirty"
  >
    {{ '수정' }}
  </button>
</div>
