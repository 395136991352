<div class="page" *ngIf="category && tags">
  <app-list-header>
    <div class="row title">
      <span *ngIf="category.icon" [innerHTML]="category.icon | safe"></span>
      <span>{{ category.title }}</span>
      <span *ngIf="tags.length > 0">&#40;{{ totalTagCount }}&#41;</span>
      <mat-icon *ngIf="isAdmin" class="icon pointer" (click)="categoryEditDialogOpen()">
        settings
      </mat-icon>
    </div>
    <div class="row description" *ngIf="category.description">
      {{ category.description }}
    </div>
    <div class="row button-wrapper right">
      <button
        mat-raised-button
        color="accent"
        *ngIf="isAdmin"
        (click)="tagAddDialogOpen()"
      >
        <mat-icon>add</mat-icon>
        New Tag
      </button>
    </div>
  </app-list-header>
  <mat-divider></mat-divider>
  <app-list [items]="tags" [loading]="loading" [link]="'/tags/'"></app-list>
  <div class="paginator" *ngIf="!loading && tags.length > 0">
    <app-paginator
      [page]="page - 1"
      [totalItemCount]="totalTagCount"
      [itemCountPerPage]="tagCountPerPage"
      (pageChange)="pageChanged($event)"
    ></app-paginator>
  </div>
</div>
