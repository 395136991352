<div class="numbers-pager">
  <div
    (click)="setNumbersPage('first')"
    [class.disabled]="maxNumbersPage === 0 || numbersPage === 0"
  >
    <i class="ic-double_left"></i>
  </div>
  <div (click)="setNumbersPage('prev')" [class.disabled]="numbersPage === 0">
    <i class="ic-left_arrow"></i>
  </div>
</div>
<div class="numbers">
  <a
    *ngFor="let number of numbers"
    (click)="_setPage(number)"
    [class.hidden]="!isVisiblePage(number)"
    [class.active]="page === number"
  >
    {{ number + 1 }}
  </a>
</div>
<div class="numbers-pager">
  <div
    (click)="setNumbersPage('next')"
    [class.disabled]="numbersPage === maxNumbersPage"
  >
    <i class="ic-right_arrow"></i>
  </div>
  <div
    (click)="setNumbersPage('end')"
    [class.disabled]="maxNumbersPage === 0 || numbersPage === maxNumbersPage"
  >
    <i class="ic-double_right"></i>
  </div>
</div>
