import { Component, OnInit, OnDestroy } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Subject } from 'rxjs';
import { map, takeUntil } from 'rxjs/operators';

import { UserService } from '../../services/user.service';
import { SearchService } from '../../services/search.service';
import { TagService } from '../../services/tag.service';

import { Contents, ContentsType } from '../../models/contents.model';
import { Tag } from '../../models/tag.model';

@Component({
  selector: 'app-search-page',
  templateUrl: './search-page.component.html',
  styleUrls: ['./search-page.component.scss'],
})
export class SearchPageComponent implements OnInit, OnDestroy {
  private unsubscriber: Subject<void> = new Subject<void>();

  public itemCountPerPage = 10;
  public totalItemCount: number;
  public selectedTab: ContentsType;
  public tabs = [
    'book',
    'post',
    'channel',
    'writer',
    'imprint',
    'library',
    'magazine',
    'tag',
  ];
  public page: number;
  public queryFilter: string;
  public count: {
    book: number;
    post: number;
    channel: number;
    writer: number;
    tag: number;
  };

  public items: Contents[] | Tag[];

  public loading: boolean;
  public isAdmin: boolean;

  constructor(
    private activatedRoute: ActivatedRoute,
    private router: Router,
    private userService: UserService,
    private searchService: SearchService,
    private tagService: TagService
  ) {}

  ngOnInit(): void {
    this.activatedRoute.queryParams
      .pipe(
        map((queryParams) => ({
            tab: queryParams.tab ? queryParams.tab : '',
            page: queryParams.page
              ? parseInt(queryParams.page, undefined)
              : undefined,
            queryFilter: queryParams.q ? queryParams.q : '',
          }))
      )
      .subscribe(({ tab, page, queryFilter }) => {
        const checkedTab = tab || 'book';
        const checkedPage = page || 1;

        if (tab !== checkedTab && page !== checkedPage) {
          const newQueryParams: any = {
            tab: checkedTab,
            page: checkedPage,
          };
          this.router.navigate([], {
            queryParams: newQueryParams,
            replaceUrl: true,
          });
          return;
        }

        if (
          this.selectedTab !== tab ||
          this.page !== page ||
          this.queryFilter !== queryFilter
        ) {
          this.loadItems(tab, page, queryFilter);
        }

        this.selectedTab = tab;
        this.page = page;
        this.queryFilter = queryFilter;
      });

    this.userService.user$
      .pipe(takeUntil(this.unsubscriber))
      .subscribe((user) => (this.isAdmin = user.isAdmin));
  }

  ngOnDestroy(): void {
    this.searchService.changeSearchText(null);
  }

  updateQueryParams(queryParams: any): void {
    this.router.navigate([], {
      queryParams,
      queryParamsHandling: 'merge',
    });
  }

  loadItems(tab: ContentsType, page: number, queryFilter: string): void {
    const offset = (page - 1) * this.itemCountPerPage;
    const limit = this.itemCountPerPage;

    this.loading = true;

    this.searchService
      .search(tab, offset, limit, queryFilter)
      .subscribe((result) => {
        this.totalItemCount = result.total;
        this.items = result.items;
        this.count = result.count;

        this.loading = false;
      });
  }

  pageChanged(page: number /* zero based */): void {
    this.updateQueryParams({ page: page + 1 });
  }
}
