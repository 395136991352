import { HttpErrorResponse } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { ActivatedRoute, Router } from '@angular/router';
import { delay, filter, map, switchMap } from 'rxjs/operators';

import { SnackBarService } from '../../services/snack.bar.service';
import { RelationshipService } from '../../services/relationship.service';
import { ContentsService } from '../../services/contents.service';

import { Contents, ContentsType } from '../../models/contents.model';

import { ConfirmDialogComponent } from '../../dialogs/confirm-dialog/confirm-dialog.component';
import { TaggingDialogComponent } from '../../dialogs/tagging-dialog/tagging-dialog.component';

@Component({
  selector: 'app-contents-page',
  templateUrl: './contents-page.component.html',
  styleUrls: ['./contents-page.component.scss'],
})
export class ContentsPageComponent implements OnInit {
  public contentsCountPerPage = 10;
  public totalContentsCount: number;
  public page: number;

  public contents: Contents;
  private contentsId: string;
  public type: ContentsType;
  public relationItems: Array<Contents>;
  public isAdmin: boolean;
  public loading: boolean;

  constructor(
    private activatedRoute: ActivatedRoute,
    private matDialog: MatDialog,
    private snackBarService: SnackBarService,
    private relationshipService: RelationshipService,
    private contentsService: ContentsService,
    private router: Router
  ) {}

  ngOnInit(): void {
    this.activatedRoute.params
      .pipe(
        switchMap((params) => this.activatedRoute.queryParams.pipe(
            delay(1),
            map((queryParams) => ({
                contentsId: params.contentsId ? params.contentsId : undefined,
                type: params.type ? params.type : undefined,
                page: queryParams.page
                  ? parseInt(queryParams.page, undefined)
                  : undefined,
              }))
          ))
      )
      .subscribe(({ contentsId, type, page }) => {
        const checkedPage = page || 1;
        const checkedContentsId = contentsId;

        if (contentsId !== checkedContentsId || page !== checkedPage) {
          const newQueryParams: any = {
            page: checkedPage,
          };
          this.router.navigate([], {
            queryParams: newQueryParams,
            replaceUrl: true,
          });
          return;
        }

        if (this.contentsId !== contentsId || this.page !== page) {
          this.loadContents(type, contentsId);
          this.loadRelates(type, contentsId, page);
        }

        this.contentsId = contentsId;
        this.type = type;
        this.page = page;
      });
  }

  loadContents(type: ContentsType, contentsId: any): void {
    this.contentsService.getContents(type, contentsId).subscribe((contents) => {
      this.contents = contents;
    });
  }

  loadRelates(type: ContentsType, contentsId: any, page: number): void {
    const offset = (page - 1) * this.contentsCountPerPage;
    const limit = this.contentsCountPerPage;

    this.loading = true;

    this.contentsService
      .getRelates(type, contentsId, offset, limit)
      .subscribe((relates) => {
        this.totalContentsCount = relates.total;
        this.relationItems = relates.items;

        this.loading = false;
      });
  }

  connect(): void {
    const dialog = this.matDialog.open(TaggingDialogComponent, {
      data: {
        id: this.contents.id,
        item: this.contents,
        relationItems: (this.contents.tags as any[]).concat(this.relationItems),
      },
    });

    dialog
      .afterClosed()
      .pipe(filter((ok) => ok))
      .subscribe(() => {
        this.loadRelates(this.type, this.contentsId, 1);
        this.loadContents(this.type, this.contentsId);
      });
  }

  deleteItem(subscribeItem: any): void {
    const dialog = this.matDialog.open(ConfirmDialogComponent, {
      data: {
        title: '삭제',
        message: '삭제합니다. 계속하시겠습니까?',
        cancelable: true,
      },
    });

    dialog
      .afterClosed()
      .pipe(
        filter((ok) => ok),
        switchMap(() => this.relationshipService.deleteRelation(
            this.contents.id,
            subscribeItem.id,
            subscribeItem.relation ? subscribeItem.relation.type : 'tags'
          ))
      )
      .subscribe(
        () => {
          this.snackBarService.openMessage('삭제되었습니다.');
          this.loadContents(this.type, this.contentsId);
          this.loadRelates(this.type, this.contentsId, 1);
        },
        (error: HttpErrorResponse) => {
          this.snackBarService.openMessage(
            `삭제하실 수 없습니다. ${error.error.error.message}`
          );
        }
      );
  }

  updateQueryParams(queryParams: any): void {
    this.router.navigate([], {
      queryParams,
      queryParamsHandling: 'merge',
    });
  }

  pageChanged(page: number /* zero based */): void {
    this.updateQueryParams({ page: page + 1 });
  }
}
