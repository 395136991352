<nav class="nav" *ngIf="isLoggedIn$ | async">
  <div class="nav-item-wrapper">
    <div class="nav-item pointer" routerLink="/tags">Tags</div>
    <div class="nav-item pointer" routerLink="/interlink">Interlink</div>
  </div>
  <div class="input-wrapper">
    <mat-icon class="search-icon"> search </mat-icon>
    <input
      class="input"
      type="text"
      matInput
      placeholder="search..."
      [(ngModel)]="queryFilter"
      (keyup.enter)="goTosearchPage()"
    />
    <mat-icon
      class="close-icon pointer"
      [class.show]="queryFilter"
      (click)="this.setSearchText(null)"
    >
      close
    </mat-icon>
  </div>
  <a class="logout-button" mat-menu-item (click)="logout()">로그아웃</a>
</nav>
