<div class="page">
  <app-list-header>
    <div class="row title">TAGS</div>
    <div class="row button-wrapper right">
      <button
        *ngIf="isAdmin"
        mat-raised-button
        color="accent"
        (click)="categoryAddDialogOpen()"
      >
        <mat-icon>add</mat-icon>
        New Category
      </button>
    </div>
  </app-list-header>
  <mat-divider></mat-divider>
  <app-list
    [items]="categories"
    [loading]="loading"
    [link]="'/categories/'"
  ></app-list>
  <div class="paginator" *ngIf="!loading && categories?.length > 0">
    <app-paginator
      [page]="page - 1"
      [totalItemCount]="totalCategoryCount"
      [itemCountPerPage]="categoryCountPerPage"
      (pageChange)="pageChanged($event)"
    ></app-paginator>
  </div>
</div>
