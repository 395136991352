import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { BehaviorSubject, Observable } from 'rxjs';
import { filter, map, tap } from 'rxjs/operators';

import { environment } from 'src/environments/environment';
import { User } from '../models/user.model';

@Injectable({
  providedIn: 'root'
})
export class UserService {
  private _user$: BehaviorSubject<User> = new BehaviorSubject(undefined);
  public user$: Observable<User> =
    this._user$.asObservable().pipe(filter((user) => user !== undefined));

  constructor(private http: HttpClient) {
    this.fetch().subscribe();
  }

  getCurrent(): User {
    return this._user$.getValue();
  }

  fetch(): Observable<User> {
    return this.http.get<any>(`${environment.bukOrigin}/user/profile`, { withCredentials: true })
      .pipe(
        map((response) => ({
            id: response.id,
            name: response.displayName,
            email: response.email,
            isAdmin: response.email.split('@')[1] === 'buk.io'
          })),
        tap((user) => {
          this._user$.next(user);
        }, (error) => {
          this._user$.next(null);
        })
      );
  }
}
