import { Injectable } from '@angular/core';
import { MatSnackBar, MatSnackBarConfig, MatSnackBarRef, SimpleSnackBar } from '@angular/material/snack-bar';

@Injectable({
  providedIn: 'root'
})
export class SnackBarService {
  constructor(
    private matSnackBar: MatSnackBar
  ) { }

  open(message: string, action?: string, config?: MatSnackBarConfig): MatSnackBarRef<SimpleSnackBar> {
    return this.matSnackBar.open(message, action, config);
  }

  openMessage(message: string, duration: number = 2500): MatSnackBarRef<SimpleSnackBar> {
    return this.matSnackBar.open(message, null, { duration });
  }
}
