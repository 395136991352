import { Component, Input, OnInit, Output, EventEmitter } from '@angular/core';
import { Router } from '@angular/router';

import { Category } from '../../models/category.model';
import { Tag } from '../../models/tag.model';
import { Contents } from '../../models/contents.model';

@Component({
  selector: 'app-list',
  templateUrl: './list.component.html',
  styleUrls: ['./list.component.scss'],
})
export class ListComponent implements OnInit {
  @Input() items: Category[] | Tag[] | Contents[];
  @Input() loading: Boolean;
  @Input() link: string;
  @Input() isDeleted = false;
  @Input() isAdmin = false;

  @Output() delete = new EventEmitter<any>();

  constructor(private router: Router) {}

  ngOnInit(): void {}

  updateQueryParams(queryParams: any): void {
    this.router.navigate([], {
      queryParams,
      queryParamsHandling: 'merge',
    });
  }

  goToPage(item: any): void {
    if (this.link) {
      this.router.navigate([this.link, item.id]);
    } else {
      if (item.type === 'tag') {
        this.router.navigate(['tags', item.id]);
      } else {
        this.router.navigate(['/interlink/', item.type, item.id]);
      }
    }
  }
}
