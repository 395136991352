import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { filter, map, takeUntil } from 'rxjs/operators';
import { Subject } from 'rxjs';
import { MatDialog } from '@angular/material/dialog';

import { CategoryService } from '../../services/category.service';
import { TagService } from '../../services/tag.service';
import { SnackBarService } from '../../services/snack.bar.service';
import { UserService } from '../../services/user.service';

import { Category } from '../../models/category.model';
import { Tag } from '../../models/tag.model';

import { CategoryEditDialogComponent } from '../../dialogs/category-edit-dialog/category-edit-dialog.component';
import { TagAddDialogComponent } from '../../dialogs/tag-add-dialog/tag-add-dialog.component';

@Component({
  selector: 'app-category-page',
  templateUrl: './category-page.component.html',
  styleUrls: ['./category-page.component.scss'],
})
export class CategoryPageComponent implements OnInit {
  private unsubscriber: Subject<void> = new Subject<void>();

  public tagCountPerPage = 10;
  public totalTagCount: number;
  public page: number;
  public queryFilter: string;

  private categoryId: string;
  public category: Category;
  public tags: Array<Tag>;

  public loading: boolean;
  public isAdmin: boolean;

  constructor(
    private activatedRoute: ActivatedRoute,
    private router: Router,
    private matDialog: MatDialog,
    private snackBarService: SnackBarService,
    private categoryService: CategoryService,
    private tagService: TagService,
    private userService: UserService
  ) {}

  ngOnInit(): void {
    this.categoryId = this.activatedRoute.snapshot.params.categoryId;

    this.activatedRoute.queryParams
      .pipe(
        map((queryParams) => ({
            page: queryParams.page
              ? parseInt(queryParams.page, undefined)
              : undefined,
            queryFilter: queryParams.q ? queryParams.q : '',
          }))
      )
      .subscribe(({ page, queryFilter }) => {
        const checkedPage = page || 1;

        if (page !== checkedPage) {
          const newQueryParams: any = {
            page: checkedPage,
          };
          this.router.navigate([], {
            queryParams: newQueryParams,
            replaceUrl: true,
          });
          return;
        }

        if (this.page !== page || this.queryFilter !== queryFilter) {
          this.loadCategory();
        }

        this.page = page;
        this.queryFilter = queryFilter;
      });

    this.userService.user$
      .pipe(takeUntil(this.unsubscriber))
      .subscribe((user) => (this.isAdmin = user.isAdmin));
  }

  loadCategory(): void {
    this.categoryService.getCategory(this.categoryId).subscribe((result) => {
      this.category = result;
      this.loadTags(this.page, this.queryFilter);
    });
  }

  loadTags(page: number, q?: string): void {
    const offset = (page - 1) * this.tagCountPerPage;
    const limit = this.tagCountPerPage;

    this.loading = true;

    this.tagService
      .getTags(this.categoryId, offset, limit, q)
      .subscribe((tags) => {
        this.totalTagCount = tags.total;
        this.tags = tags.items;

        if (page > 1 && tags.items.length === 0) {
          this.updateQueryParams({ page: page - 1 });
        }

        this.loading = false;
      });
  }

  updateQueryParams(queryParams: any): void {
    this.router.navigate([], {
      queryParams,
      queryParamsHandling: 'merge',
    });
  }

  tagAddDialogOpen(): void {
    const dialog = this.matDialog.open(TagAddDialogComponent, {
      data: {
        categoryId: this.categoryId,
      },
    });
    dialog
      .afterClosed()
      .pipe(filter((ok) => ok))
      .subscribe(() => {
        this.loadTags(1);
        this.snackBarService.openMessage('태그가 추가되었습니다.');
      });
  }

  categoryEditDialogOpen(): void {
    const dialog = this.matDialog.open(CategoryEditDialogComponent, {
      data: {
        category: this.category,
      },
    });
    dialog
      .afterClosed()
      .pipe(filter((result) => result === 'edit' || result === 'delete'))
      .subscribe((result) => {
        if (result === 'delete') {
          this.router.navigateByUrl('');
        } else {
          this.loadCategory();
          this.snackBarService.openMessage('카테고리가 수정되었습니다.');
        }
      });
  }

  pageChanged(page: number /* zero based */): void {
    this.updateQueryParams({ page: page + 1 });
  }
}
