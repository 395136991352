<div mat-dialog-title>
  <div>연결하기</div>
  <button mat-icon-button [mat-dialog-close]="isDirty">
    <mat-icon class="close-icon">close</mat-icon>
  </button>
</div>
<div mat-dialog-content>
  <mat-form-field class="full-width">
    <mat-label>Search...</mat-label>
    <input
      matInput
      [(ngModel)]="searchText"
      (keyup.enter)="loadItems(tab)"
      (input)="searchChange$.next($event.target.value)"
      cdkFocusInitial
    />
  </mat-form-field>
  <div class="suggestion-tags" *ngIf="suggestedTags">
    <div class="tabs" *ngIf="tabs?.length > 0">
      <a
        class="tab"
        *ngFor="let item of tabs"
        [class.active]="tab === item"
        (click)="loadItems(item)"
        >{{ item | titlecase }}&#40;{{ count[item] }}&#41;</a
      >
    </div>
    <app-suggestion-tag-list
      class="suggestion-tag-list"
      [items]="suggestedTags"
      [selectedItems]="selectedItems"
      [loading]="loading"
      (clickItem)="updateAddedItems($event)"
    ></app-suggestion-tag-list>
  </div>
  <div class="empty" *ngIf="!suggestedTags">검색어를 입력해 주세요.</div>
  <div class="buttons">
    <div>총 {{ selectedItems.length }}개</div>
    <mat-form-field>
      <mat-select [(ngModel)]="selectedRelation">
        <mat-option
          *ngFor="let relation of relationes"
          [value]="relation.title"
        >
          {{ relation.title }}
        </mat-option>
      </mat-select> </mat-form-field
    >로
    <button
      mat-raised-button
      color="accent"
      (click)="connect()"
      [disabled]="selectedItems.length < 1"
    >
      연결
    </button>
  </div>
</div>
