<input
  #input
  type="file"
  accept="image/*"
  (change)="fileChanged($event.target.files)"
/>

<div class="editor" [class.visible]="fileName">
  <div class="image-title">
    {{ fileName }}
  </div>
  <div class="cropper">
    <image-cropper
      [imageFileChanged]="file"
      [maintainAspectRatio]="false"
      [outputType]="'file'"
      [format]="'png'"
      (loadImageFailed)="loadImageFailed()"
      (imageLoaded)="loadImageSucceed()"
      (startCropImage)="startCropImage()"
    >
    </image-cropper>
  </div>
  <div class="tools">
    <button mat-icon-button (click)="input.click()">
      <mat-icon>folder_open</mat-icon>
    </button>
    <button
      mat-icon-button
      (click)="imageCropperComp.rotateLeft(); isRotated = true"
    >
      <mat-icon>rotate_left</mat-icon>
    </button>
    <button
      mat-icon-button
      (click)="imageCropperComp.rotateRight(); isRotated = true"
    >
      <mat-icon>rotate_right</mat-icon>
    </button>
    <button mat-icon-button>
      <mat-icon (click)="crop()">crop</mat-icon>
    </button>
  </div>
</div>

<div
  class="placeholder mat-subheading-1"
  [class.dragging]="isDragging"
  *ngIf="!fileName"
  (drop)="dragAndDrop($event)"
  (dragover)="dragAndDrop($event)"
  (dragleave)="dragAndDrop($event)"
>
  <mat-icon>cloud_upload</mat-icon>
  <div>
    <span class="text-button" (click)="input.click()">이미지를 선택하거나</span
    >여기로 드래그 해주세요
  </div>
</div>

<div class="dialog-buttons">
  <button mat-button (click)="dialogRef.close()">취소</button>
  <button
    mat-raised-button
    color="accent"
    (click)="upload()"
    [disabled]="!file"
  >
    업로드
  </button>
</div>

<div class="image-upload-loader" [class.active]="isUploading">
  <mat-progress-spinner
    color="accent"
    mode="indeterminate"
    diameter="60"
  ></mat-progress-spinner>
</div>
