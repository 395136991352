<div *ngIf="data.title" mat-dialog-title>{{ data.title }}</div>
<div mat-dialog-content [innerHTML]="data.message"></div>
<div class="buttons">
  <button mat-button *ngIf="data.cancelable" (click)="dialogRef.close(false)">
    {{ data.cancelButtonText || '취소' }}
  </button>
  <button
    mat-raised-button
    [color]="data.isNegativeAction ? 'warn' : 'accent'"
    (click)="dialogRef.close(true)"
  >
    {{ data.okButtonText || '확인' }}
  </button>
</div>
