<div mat-dialog-title>
  <div>카테고리 수정</div>
  <button mat-icon-button mat-dialog-close>
    <mat-icon class="close-icon">close</mat-icon>
  </button>
</div>
<div mat-dialog-content>
  <form
    [formGroup]="categoryPropertyFormGroup"
    *ngIf="categoryPropertyFormGroup"
  >
    <mat-form-field class="full-width">
      <mat-label>이름</mat-label>
      <input matInput formControlName="title" />
    </mat-form-field>
    <mat-form-field class="full-width">
      <mat-label>설명</mat-label>
      <textarea
        matInput
        formControlName="description"
        matTextareaAutosize
        matAutosizeMaxRows="3"
      ></textarea>
    </mat-form-field>
    <mat-form-field class="full-width">
      <mat-label>아이콘</mat-label>
      <input matInput formControlName="icon" />
      <mat-error
        *ngIf="categoryPropertyFormGroup.controls.icon.hasError('iconError')"
        >SVG 아이콘을 넣어주세요.</mat-error
      >
    </mat-form-field>
    <div class="label">Level</div>
    <mat-radio-group formControlName="level">
      <mat-radio-button value="1" class="level">1</mat-radio-button>
      <mat-radio-button value="2" class="level">2</mat-radio-button>
      <mat-radio-button value="3" class="level">3</mat-radio-button>
      <mat-radio-button value="4" class="level">4</mat-radio-button>
    </mat-radio-group>
  </form>
</div>
<div class="buttons">
  <button
    mat-button
    color="warn"
    (click)="confirmDialogOpen()"
  >
    삭제
  </button>
  <button
    mat-raised-button
    color="accent"
    (click)="edit()"
    [disabled]="
      categoryPropertyFormGroup.invalid || !categoryPropertyFormGroup.dirty
    "
  >
    {{ '수정' }}
  </button>
</div>
