<div class="empty-msg" *ngIf="!loading && items?.length === 0">
  목록이 비어있습니다.
</div>
<ng-container *ngIf="items?.length > 0 && !loading">
  <div
    class="list-item"
    *ngFor="let item of items"
    [ngClass]="item.type"
    (click)="goToPage(item)"
  >
    <div class="relation" *ngIf="item.relation">
      {{ item.relation.type | titlecase }}
    </div>
    <div
      class="list-item-image-wrapper"
      *ngIf="item.image_url"
      [style.background-image]="'url(' + item.image_url + ')'"
    ></div>
    <div class="list-item-info">
      <div class="list-item-type" *ngIf="item.type">{{ item.type }}</div>
      <div class="list-item-category" *ngIf="item.category">
        {{ item.category.title }}
      </div>
      <div class="list-item-title pointer">
        <div
          class="list-item-icon"
          *ngIf="item.icon"
          [innerHTML]="item.icon | safe"
        ></div>
        <span class="list-item-code" *ngIf="item.code">
          &#91;{{ item.code }}&#93;
        </span>
        <span>{{ item.title }}</span>
        <span class="list-item-length" *ngIf="!item.type && item.tag_count > 0">
          &#40;{{ item.tag_count }}&#41;</span
        >
      </div>
      <div class="list-item-description" *ngIf="item.description">
        {{ item.description }}
      </div>
      <div class="list-item-tags" *ngIf="item.tags?.length > 0">
        <mat-chip-list>
          <mat-chip
            *ngFor="let tag of item.tags"
            [routerLink]="['/tags', tag.id]"
            ><ng-container *ngIf="tag.category"
              >{{ tag.category.title }} - </ng-container
            >{{ tag.title }}
          </mat-chip>
        </mat-chip-list>
      </div>
    </div>
    <div
      class="list-item-delete-icon"
      *ngIf="isDeleted && isAdmin"
      (click)="$event.stopPropagation(); delete.next(item)"
    >
      <mat-icon>close</mat-icon>
    </div>
  </div>
</ng-container>
<div *ngIf="loading" class="loader" [class.empty]="!items">
  <mat-spinner class="spinner" [diameter]="40" color="accent"></mat-spinner>
</div>
