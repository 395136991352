import { Component, OnInit, Inject } from '@angular/core';
import {
  FormGroup,
  FormControl,
  Validators,
  ValidatorFn,
  AbstractControl,
} from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA, MatDialog } from '@angular/material/dialog';
import { HttpErrorResponse } from '@angular/common/http';
import { filter, switchMap } from 'rxjs/operators';

import { CategoryService } from '../../services/category.service';
import { SnackBarService } from '../../services/snack.bar.service';
import { Category } from '../../models/category.model';

import { ConfirmDialogComponent } from '../../dialogs/confirm-dialog/confirm-dialog.component';

export function iconValidator(): ValidatorFn {
  return (control: AbstractControl): { [key: string]: any } | null => {
    if (control.value === '' || /\<\/svg\>$/.test(control.value)) {
      return null;
    } else {
      return { iconError: true };
    }
  };
}

@Component({
  selector: 'app-category-edit-dialog',
  templateUrl: './category-edit-dialog.component.html',
  styleUrls: ['./category-edit-dialog.component.scss'],
})
export class CategoryEditDialogComponent implements OnInit {
  public categoryPropertyFormGroup: FormGroup;

  constructor(
    public dialogRef: MatDialogRef<CategoryEditDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: { category: Category },
    private categoryService: CategoryService,
    private snackBarService: SnackBarService,
    private matDialog: MatDialog
  ) {}

  ngOnInit(): void {
    this.dialogRef.updateSize('420px');
    this.dialogRef.disableClose = true;

    this.categoryPropertyFormGroup = new FormGroup({
      id: new FormControl(this.data.category ? this.data.category.id : ''),
      title: new FormControl(
        this.data.category ? this.data.category.title : '',
        [Validators.required]
      ),
      description: new FormControl(
        this.data.category ? this.data.category.description : ''
      ),
      icon: new FormControl(this.data.category ? this.data.category.icon : '', [
        iconValidator(),
      ]),
      level: new FormControl(
        this.data.category ? this.data.category.level : ''
      ),
    });
  }

  edit(): void {
    this.categoryService.update(this.categoryPropertyFormGroup.value).subscribe(
      () => {
        this.dialogRef.close('edit');
      },
      (error: HttpErrorResponse) => {
        this.snackBarService.openMessage(
          `수정되지 않음 ${error.error.error.message}`
        );
      }
    );
  }

  confirmDialogOpen() {
    const dialog = this.matDialog.open(ConfirmDialogComponent, {
      data: {
        title: '카테고리 삭제',
        message: '카테고리를 삭제합니다. 계속하시겠습니까?',
        cancelable: true,
      },
    });

    dialog
      .afterClosed()
      .pipe(
        filter((ok) => ok),
        switchMap(() => this.categoryService.delete(this.data.category.id))
      )
      .subscribe(
        () => {
          this.snackBarService.openMessage('카테고리가 삭제되었습니다.');
          this.dialogRef.close('delete');
        },
        (error: HttpErrorResponse) => {
          this.snackBarService.openMessage(
            `태그가 있는 경우 삭제하실 수 없습니다. ${error.error.error.message}`
          );
        }
      );
  }
}
