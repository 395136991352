import { Component, OnInit, Inject, ViewChild } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { from as From, of as Of, Observable, Subscriber } from 'rxjs';
import { finalize, map, switchMap } from 'rxjs/operators';
import {
  ImageCroppedEvent,
  ImageCropperComponent,
  CropperPosition,
} from 'ngx-image-cropper';

@Component({
  selector: 'app-image-upload-dialog',
  templateUrl: './image-upload-dialog.component.html',
  styleUrls: ['./image-upload-dialog.component.scss'],
})
export class ImageUploadDialogComponent implements OnInit {
  @ViewChild(ImageCropperComponent) imageCropperComp: ImageCropperComponent;

  public file: any;
  public fileName = '';

  public initialCropperPosition: CropperPosition;

  public isDragging: boolean;
  public isUploading: boolean;
  public isRotated: boolean;

  constructor(
    public dialogRef: MatDialogRef<ImageUploadDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any
  ) {}

  ngOnInit(): void {}

  fileChanged(files): void {
    if (!files || files.length === 0) {
      return;
    }

    if (files[0].type.indexOf('image') === -1) {
      return;
    }

    this.file = files[0];
    this.fileName = files[0].name.split(/(\\|\/)/g).pop();
  }

  loadImageFailed(): void {
    this.initialCropperPosition = undefined;
  }

  startCropImage(): void {
    if (!this.initialCropperPosition) {
      this.initialCropperPosition = Object.assign(
        {},
        this.imageCropperComp.cropper
      );
    }
  }

  loadImageSucceed(): void {
    this.initialCropperPosition = undefined;
  }

  crop(): void {
    From(this.imageCropperComp.crop() as Promise<ImageCroppedEvent>).subscribe(
      (event: ImageCroppedEvent) => {
        this.file = event.file;
      }
    );
  }

  dragAndDrop(event: DragEvent): void {
    event.preventDefault();
    event.stopPropagation();

    this.isDragging = event.type === 'dragover';

    if (event.type === 'drop') {
      this.fileChanged(event.dataTransfer.files);
    }
  }

  imageUrlChange(file: any): Observable<string> {
    return Observable.create((observer: Subscriber<any>): void => {
      const fileReader = new FileReader();
      fileReader.onload = () => {
        observer.next(fileReader.result);
        observer.complete();
      };
      fileReader.readAsDataURL(file);
    });
  }

  upload(): void {
    let croppedFileObservable;

    if (
      this.initialCropperPosition &&
      this.imageCropperComp.cropper &&
      (this.isRotated ||
        this.isCropperChanged(
          this.initialCropperPosition,
          this.imageCropperComp.cropper
        ))
    ) {
      croppedFileObservable = From(
        this.imageCropperComp.crop() as Promise<ImageCroppedEvent>
      ).pipe(map((event: ImageCroppedEvent) => event.file));
    } else {
      croppedFileObservable = Of(this.file);
    }

    croppedFileObservable
      .pipe(
        switchMap((file: any) => {
          this.isUploading = true;
          return this.imageUrlChange(file);
        }),
        finalize(() => (this.isUploading = false))
      )
      .subscribe((imageDataUrl: string) => {
        this.dialogRef.close(imageDataUrl);
      });
  }

  isCropperChanged(cp1: CropperPosition, cp2: CropperPosition): boolean {
    return Object.keys(cp1).some((cpKey) => cp1[cpKey] !== cp2[cpKey]);
  }
}
